/* ------------------------------------------------------- */
/* TEST1: mixed units */
    .wrapper-test1 {
        width: 100%;
        display: grid;
        grid-template-columns: 100px 30% 1fr;
        /* grid-template-rows:    200px 100px; */
        grid-template-rows:    10vh 90vh;
        /* grid-gap: 1rem; */
        grid-gap: 0rem;
    }

    .wrapper-test1 > * {
        border-color: red;
        border-width: 1px;
        border-style: dashed;
    }
/* ------------------------------------------------------- */

/* ------------------------------------------------------- */
/* TEST2: stackoverflow example */
    #wrapper-test2 {
        display: grid;
        gap: 0px;
        grid-template-columns: auto 1fr auto;
        grid-template-rows: auto;
        grid-template-areas: 
            "logo search menu"
            "left content content"
    }

    #wrapper-test2 > * {
        border-color: red;
        border-width: 1px;
        border-style: dashed;

        box-sizing: border-box;
    }

    /* Logo: use up a little width as possible */
    #logo {
        grid-area: logo;
        max-width: 200px;
        min-width: 100px;
    }
    /* Search: use full width */
    #search {
        grid-area: search;
    }
    /* Menu: use up a little width as possible */
    #menu {
        grid-area: menu;
        max-width: 80px;
    }
    /* Left: fixed 50px */
    #left {
        grid-area: left;
        max-width: 50px;
    }
    /* Content: use full width */
    #content {
        grid-area: content;
    }
/* ------------------------------------------------------- */

/* ------------------------------------------------------- */
/* TEST3: grid areas SEM USO*/
    .wrapper-3-home {
        position: fixed;
        top: 0px;

        display: grid;
        width: 100%;
        /* height: 600px; */
        height: 100%;
        /* minmax(200px, 1fr) */
        /* grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 50px; */
        grid-template-columns: minmax(10px, 50px) minmax(10px, 50px) minmax(10px, 50px) 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 50px;
        /* grid-template-rows: 10vh 90vh; */
        grid-template-rows: 10vh 45vh 45vh 0vh;
        /* grid-template-rows: 10vh 45vh 45vh; */
        grid-gap: 0rem;
        grid-template-areas:
            ".      .       .       .       .       .       .       .       .       .       .       ."
            "left_  .       .       logo    logo    logo    logo    logo    logo    .       .       ."
            "left_  .       search  search  search  search  search  search  search  search  .       ."
            "menu   content .       .       .       .       .       .       .       .       .       ."
    }
    .wrapper-3 {
        position: fixed;
        top: 0px;
        left: 0px;

        display: grid;
        width: 100%;
        /* height: 600px; */
        height: 100%;
        /* minmax(200px, 1fr) */
        /* grid-template-columns: 50px 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 50px; */
        grid-template-columns: minmax(10px, 50px) minmax(10px, 50px) minmax(10px, 50px) 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 50px;
        grid-template-rows: 10vh 90vh;
        grid-gap: 0rem;
        grid-template-areas:
            "logo   logo    logo    search  search  search  search  search  search  search  search  menu_"
            "left_  content content content content content content content content content content content"
    }
    .logo-wrapper {
        display: contents;
    }
    .wrapper-3 > * {
        /* border-color: red;
        border-width: 1px;
        border-style: dashed; */
    }
    .logo-3 {
        grid-area: logo;
        /* max-width: 200px; */
    }
    .search-3 {
        grid-area: search;
    }
    .menu-3 {
        grid-area: menu_;
    }
    .left-3 {
        grid-area: left_;
    }
    .content-3 {
        grid-area: content;
    }

/* ------------------------------------------------------- */


/* ------------------------------------------------------- */
/* TEST4: CSS Grid layout 12 column */
    .wrapper {
        display: grid;
        grid-template-columns: repeat(12, [col-start] 1fr);
        gap: 0px;

        /* align-content: space-around;
        justify-content: space-between; */
    }

    .wrapper > * {
        grid-column: col-start / span 12;

        /* border-color: red; */
        /* border-width: 1px; */
        /* border-style: dashed; */
    }
/* ------------------------------------------------------- */

/* ------------------------------------------------------- */
/* TEST6: REACT FLIP TOOLKIT */
/* .logo-original { */
.logo-retracted {
    /* background-color: green; */
    grid-area: logo;
    height: 100%;
    width: 100%;
    padding: 0.5em;

    /* border-color: red;
    border-style: dashed;
    border-width: 1px; */
}

/* .logo-changed { */
.logo-expanded {
    /* --logo-top: 25vh; */
    --logo-bottom: 60vh;
    /* FX: set a minimum size for the logo */
    --logo-width: clamp(180px, 30vw, 30vw);
    position: fixed;
    transform-origin: center;
    /* FX: using bottom to keep a relative position between logo/search */
    bottom: var(--logo-bottom);
    left: calc(50% - calc(var(--logo-width) / 2));
    /* height: 30vh; */
    width: var(--logo-width);

    /* background-color: red; */
    /* border-color: green;
    border-style: dashed;
    border-width: 2px; */
}

/* .search-original { */
.search-retracted {
    grid-area: search;

    /* background-color: green; */
    /* border-color: red;
    border-style: dashed;
    border-width: 1px; */
}

/* .search-changed { */
.search-expanded {
    --search-top: 45vh;
    --search-width: 60vw;
    position: fixed;
    transform-origin: center;
    top: var(--search-top);
    left: calc(50% - calc(var(--search-width) / 2));
    /* height: 30vh; */
    width: var(--search-width);

    /* background-color: red; */
    /* border-color: green;
    border-style: dashed;
    border-width: 2px; */
}

/* .typed-original { */
.typed-retracted {
    /* grid-area: content; */
    /* text-align: center; */
    /* vertical-align: middle; */
    /* padding-left: 10vw; */
    /* padding-top: 50vh; */

    --typed-top: 55vh;
    --typed-width: 60vw;
    /* background-color: yellow; */
    position: fixed;
    transform-origin: center;
    top: var(--typed-top);
    left: calc(50% - calc(var(--typed-width) / 2));
    width: var(--typed-width);
    /* height: 0; */
    display: none;
}
/* .typed-changed { */
.typed-expanded {
    --typed-top: 55vh;
    --typed-width: 60vw;
    /* background-color: yellow; */
    position: fixed;
    transform-origin: center;
    top: var(--typed-top);
    left: calc(50% - calc(var(--typed-width) / 2));
    width: var(--typed-width);
}


/* ------------------------------------------------------- */



@media (min-width: 500px) {
  .side {
    grid-column: col-start / span 3;
    grid-row: 3;
  }
  .ad {
    grid-column: col-start / span 3;
    grid-row: 4;
  }
  .content,
  .main-footer {
    grid-column: col-start 4 / span 9;
  }
  nav ul {
    display: flex;
    justify-content: space-between;
  }
}

/* FX LAYOUT */
/* @media (min-width: 700px) { */
  .header-logo {
    grid-column: col-start / span 2;
    grid-row: 1 / 4;

    height: 10vh;
    /* max-width: 100px; */
  }
  .header-search {
    grid-column: col-start 3 / span 8;
    grid-row: 1 / 4;
  }
  .header-menu {
    grid-column: col-start 11 / span 2;
    grid-row: 1 / 4;
  }
  .body-left {
    grid-column: col-start / span 1;
    grid-row: 4;

    height: 90vh;
  }
  .body-content {
    grid-column: col-start 2 / span 11;
    grid-row: 4;

    height: 90vh;
  }
/* } */

/* ORIGINAL EXAMPLE */
@media (min-width: 700px) {
  .main-nav {
    grid-column: col-start / span 2;
    grid-row: 2 / 4;
  }
  .content {
    grid-column: col-start 3 / span 8;
    grid-row: 2 / 4;
  }
  .side {
    grid-column: col-start 11 / span 2;
    grid-row: 2;
  }
  .ad {
    grid-column: col-start 11 / span 2;
    grid-row: 3;
  }
  .main-footer {
    grid-column: col-start / span 12;
  }
  nav ul {
    flex-direction: column;
  }
}

/* END: CSS Grid layout --------------------------------------------------------------*/

/* BEGIN: Control Column - Left --------------------------------------------------------------*/
.control-col-v-left {
    /* width: 40px; */
    /* position: fixed; */
    position: absolute;
    bottom: 0px;
    /* left: 40px; */
    /* right: auto; */
    z-index: 10;
    /* color: var(--light-slate); */
    transform-origin: center center;
    transform: rotate(180deg);
}
@media (max-width: 768px){
    .control-col-v-left {
        /* width: 40px; */
    }
}
@media (max-width: 480px){
    .control-col-v-left {
        /* width: 30px;
        left: 10px; */
    }
}
/* END: Control Column - Left --------------------------------------------------------------*/


@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}


.bg-image-control::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    /* background-color: rgba(0,0,0,0.4); */
    background-color: var(--bg-overlay);
}


.v-right::after {
    content: "";
    display: block;
    width: 1px;
    height: 90px;
    margin: 0px auto;
    /* background-color: var(--light-slate); */
    background-color: grey;
    /* background-color: var(--fx-primary-color) */
}


/* .v-left::after { */
.v-left::before {
    content: "";
    display: block;
    width: 1px;
    height: 90px;
    margin: 0px auto;
    background-color: grey;
    /* background-color: var(--fx-primary-color) */
}




.alert-enter {
  /* opacity: 0.5; */
  /* transform: scale(0.9); */
    background: yellow;
}
.alert-enter-active {
  /* opacity: 1; */
  /* opacity: 0.8; */
  /* transform: translateX(100); */
  background: black;
  /* transition: opacity 300ms, transform 300ms; */
  transition: background 1000ms;
}
.alert-enter-done {
  background: black;
}


.alert-exit {
  /* opacity: 1; */
  /* opacity: 0.8; */
    /* transform: translateX(100); */
    background: blue;

}
.alert-exit-active {
  /* opacity: 0.5; */
        background: magenta;
  /* transform: scale(0.9); */
  /* transition: opacity 300ms, transform 300ms; */
  /* transition: transform 300ms; */
    transition: background 1000ms;
}
.alert-exit-done {
  background: magenta;
}

.FxAppPage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

h1,
p {
  margin: 0;
  padding: 0;
  display: inline-block;
  /* font-family: sans-serif;
  font-weight: bold; */
}

h1 {
  /* color: blue; */
}

button {
  margin-bottom: 1rem;
}
