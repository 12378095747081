 :root {
   --dark-navy: #020c1b;
    --navy: #0a192f;
    --light-navy: #112240;
    --lightest-navy: #233554;
    --navy-shadow: rgba(2,12,27,0.7);
    --dark-slate: #495670;
    --slate: #8892b0;
    --light-slate: #a8b2d1;
    --lightest-slate: #ccd6f6;
    --white: #e6f1ff;
    --green: #64ffda;
    --green-tint: rgba(100,255,218,0.1);
    --pink: #f57dff;
    --blue: #57cbff;
    --font-sans: "Calibre","Inter","San Francisco","SF Pro Text",-apple-system,system-ui,sans-serif;
    --font-mono: "SF Mono","Fira Code","Fira Mono","Roboto Mono",monospace;
    --fz-xxs: 12px;
    --fz-xs: 13px;
    --fz-sm: 14px;
    --fz-md: 16px;
    --fz-lg: 18px;
    --fz-xl: 20px;
    --fz-xxl: 22px;
    --fz-heading: 32px;
    --border-radius: 4px;
    --nav-height: 100px;
    --nav-scroll-height: 70px;
    --tab-height: 42px;
    --tab-width: 120px;
    --easing: cubic-bezier(0.645,0.045,0.355,1);
    --transition: all 0.25s cubic-bezier(0.645,0.045,0.355,1);
    --hamburger-width: 30px;
    --ham-before: top 0.1s ease-in 0.25s,opacity 0.1s ease-in;
    --ham-before-active: top 0.1s ease-out,opacity 0.1s ease-out 0.12s;
    --ham-after: bottom 0.1s ease-in 0.25s,transform 0.22s cubic-bezier(0.55,0.055,0.675,0.19);
    --ham-after-active: bottom 0.1s ease-out,transform 0.22s cubic-bezier(0.215,0.61,0.355,1) 0.12s;
}

/* <main> --------------------------------------------------------------*/
main.fillHeight {
    padding: 0px 150px;
}

@media (max-width: 1080px){
    main.fillHeight {
        /* padding: 0px 100px; */
        padding: 0px;
    }
}

@media (max-width: 768px){
    main.fillHeight {
        /* padding: 0px 50px; */
        padding: 0px;
    }
}

@media (max-width: 480px){
    main.fillHeight {
        /* padding: 0px 25px; */
        padding: 0px;
    }
}

main {
    margin: 0px auto;
    width: 100%;
    max-width: 1600px;
    /* min-height: 100vh; */
    min-height: 100%;
    /* padding: 200px 150px; */

    counter-reset: section 0;
    display: flex;
    flex-direction: column;
}

@media (max-width: 1080px){
    main {
        /* padding: 200px 100px; */
    }
}

@media (max-width: 768px){
    main {
        /* padding: 150px 50px; */
    }
}

@media (max-width: 480px){
    main {
        /* padding: 125px 25px; */
    }
}
/* <main> --------------------------------------------------------------*/


/* <section> --------------------------------------------------------------*/
section {
    margin: 0px auto;
    /* padding: 100px 0px; */
    max-width: 1000px;
}

@media (max-width: 768px){
    section {
        /* padding: 80px 0px; */
    }
}

@media (max-width: 480px){
    section {
        /* padding: 60px 0px; */
    }
}

::selection {
    background-color: var(--lightest-navy);
    color: var(--lightest-slate);
}

/* <section> --------------------------------------------------------------*/


/* <ul> --------------------------------------------------------------*/
.portfolio-list {
    list-style: none;
    padding: 0px;
    margin: 0px;
}


/* <li> --------------------------------------------------------------*/

.portfolio-item {
    position: relative;
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(12, 1fr);
    -webkit-box-align: center;
    align-items: center;
}

.portfolio-item:not(:last-of-type) {
    margin-bottom: 100px;
}

@media (max-width: 768px){
    .portfolio-item {
        box-shadow: 0 10px 30px -15px var(--navy-shadow);
        transition: var(--transition);
    }
}

@media (max-width: 768px){
    .portfolio-item:not(:last-of-type) {
        margin-bottom: 40px;
    }
}


/* PROJECT CONTENT --------------------------------------------------------------*/

.portfolio-item .portfolio-content {
    position: relative;
    grid-area: 1 / 1 / -1 / 7;
}

@media (max-width: 1080px) {
    .portfolio-item .portfolio-content {
        grid-column: 1 / 9;
    }
}

@media (max-width: 768px){
    .portfolio-item .portfolio-content {
        display: flex;
        flex-direction: column;
        -webkit-box-pack: center;
        justify-content: center;
        height: 100%;
        grid-column: 1 / -1;
        padding: 40px 40px 30px;
        z-index: 5;
    }
}
@media (max-width: 480px){
    .portfolio-item .portfolio-content {
        padding: 30px 25px 20px;
    }
}


.portfolio-item:nth-of-type(2n+1) .portfolio-content {
    grid-column: 7 / -1;
    text-align: right;
}

@media (max-width: 1080px){
    .portfolio-item:nth-of-type(2n+1) .portfolio-content {
        grid-column: 5 / -1;
    }
}

@media (max-width: 768px){
    .portfolio-item:nth-of-type(2n+1) .portfolio-content {
        grid-column: 1 / -1;
        padding: 40px 40px 30px;
        text-align: left;
    }
}
@media (max-width: 480px){
    .portfolio-item:nth-of-type(2n+1) .portfolio-content {
        padding: 25px 25px 20px;
    }
}
/* PROJECT CONTENT --------------------------------------------------------------*/



/* PROJECT SYNOPIS --------------------------------------------------------------*/
.portfolio-item .project-synopsis {
    /* box-shadow: 0 10px 30px -15px var(--navy-shadow); */
    box-shadow: 0 10px 30px -15px black;
    transition: var(--transition);
    position: relative;
    z-index: 2;
    padding: 25px;
    border-radius: var(--border-radius);
    /* background-color: var(--light-navy); */
    /* background-color: Gainsboro; */
    background-color: var(--project-synopsis-bg);
    color: var(--mui-color-primary);
    font-size: var(--fz-sm);
}

@media (max-width: 768px){
    .portfolio-item .project-synopsis {
        padding: 20px 0px;
        background-color: transparent;
        box-shadow: none;
    }
}
/* PROJECT SYNOPSIS --------------------------------------------------------------*/


/* PROJECT OVERLINE --------------------------------------------------------------*/
.portfolio-item .project-overline {
    margin: 10px 0px;
    color: var(--mui-color-secondary);
    /* color:dimgray; */
    font-family: var(--font-mono);
    font-size: var(--fz-md);
    /* font-weight: 400; */
    font-weight: bold;
    z-index: 2;
    text-shadow: var(--overline-text-shadow);
}

p:last-child, p:last-of-type {
    margin: 0px;
}

p {
    margin: 0px 0px 15px;
}

p:last-child, p:last-of-type {
    margin: 0px;
}
/* PROJECT OVERLINE --------------------------------------------------------------*/

/* PROJECT TITLE --------------------------------------------------------------*/

.portfolio-item .project-title {
    /* color: var(--lightest-slate); */
    color: var(--mui-color-primary);
    font-size: clamp(24px, 5vw, 28px);
    font-weight: bold;
    z-index: 20;
    margin-bottom: 0.5rem;
    text-shadow: var(--project-title-text-shadow);
}

@media (max-width: 768px){
    .portfolio-item .project-title {
        /* color: var(--white); */
        /* text-shadow: var(--project-title-text-shadow); */
    }
}

@media (max-width: 768px){
    .portfolio-item .project-title a {
        position: static;
    }
}

@media (max-width: 768px){
    .portfolio-item .project-title a::before {
        content: "";
        display: block;
        position: absolute;
        z-index: 0;
        width: 100%;
        height: 100%;
        top: 0px;
        left: 0px;
    }
}
/* PROJECT TITLE --------------------------------------------------------------*/


/* PROJECT TECH LIST --------------------------------------------------------------*/
    .portfolio-item .project-tech-list {
        display: flex;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;
        margin: 25px 0px 10px;
        padding: 0px;
        list-style: none;
    }

    @media (max-width: 768px){
        .portfolio-item .project-tech-list {
            margin: 10px 0px;
        }
    }

    .portfolio-item:nth-of-type(2n+1) .project-tech-list {
        -webkit-box-pack: end;
        justify-content: flex-end;
    }

    @media (max-width: 768px){
        .portfolio-item:nth-of-type(2n+1) .project-tech-list {
            -webkit-box-pack: start;
            justify-content: flex-start;
        }
    }

    .portfolio-item .project-tech-list li {
        margin: 0px 20px 5px 0px;
        /* color: var(--light-slate); */
        /* color: dimgray; */
        color: var(--mui-color-primary);
        font-family: var(--font-mono);
        font-size: var(--fz-xs);
        text-shadow: var(--techlist-text-shadow);
        white-space: nowrap;
    }

    .portfolio-item .project-tech-list div {
        margin: 0px 20px 5px 0px;
        /* color: var(--light-slate); */
        /* color: dimgray; */
        color: var(--mui-color-primary);
        font-family: var(--font-mono);
        font-size: var(--fz-xs);
        white-space: nowrap;
    }

    @media (max-width: 768px){
        .portfolio-item:nth-of-type(2n+1) .project-tech-list div {
            margin: 0px 10px 5px 0px;
        }
        .portfolio-item:nth-of-type(2n+1) .project-tech-list li {
            margin: 0px 10px 5px 0px;
        }
    }

    .portfolio-item:nth-of-type(2n+1) .project-tech-list li {
        margin: 0px 0px 5px 20px;
    }

    @media (max-width: 768px){
        .portfolio-item .project-tech-list div {
            margin: 0px 10px 5px 0px;
            /* color: var(--lightest-slate); */
            color: var(--mui-color-primary);
        }
        .portfolio-item .project-tech-list li {
            margin: 0px 10px 5px 0px;
            /* color: var(--lightest-slate); */
            color: var(--mui-color-primary);
        }
    }

/* PROJECT TECH LIST --------------------------------------------------------------*/


/* PROJECT LINKS --------------------------------------------------------------*/
    @media (max-width: 768px){
        .portfolio-item:nth-of-type(2n+1) .project-links {
            -webkit-box-pack: start;
            justify-content: flex-start;
            margin-left: -10px;
            margin-right: 0px;
        }
    }

    .portfolio-item:nth-of-type(2n+1) .project-links {
        -webkit-box-pack: end;
        justify-content: flex-end;
        margin-left: 0px;
        margin-right: -10px;
    }

    .portfolio-item .project-links {
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        position: relative;
        margin-top: 10px;
        margin-left: -10px;
        color: var(--lightest-slate);
    }

    .portfolio-item .project-links a {
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        padding: 10px;
    }

    a {
        display: inline-block;
        text-decoration: none;
        text-decoration-skip-ink: auto;
        color: inherit;
        position: relative;
        transition: var(--transition);
    }
    .portfolio-item .project-links a svg {
        width: 20px;
        height: 20px;
    }

    svg.feather {
        fill: none;
    }

    svg.svg-logo {
        width: 100%;
        height: 100%;
        fill: currentcolor;
        vertical-align: middle;
    }
/* PROJECT LINKS --------------------------------------------------------------*/



/* PROJECT IMAGE --------------------------------------------------------------*/
    .portfolio-item .portfolio-image {
        /* box-shadow: 0 10px 30px -15px var(--navy-shadow); */
        box-shadow: 0 10px 30px -15px black;
        transition: var(--transition);
        grid-area: 1 / 6 / -1 / -1;
        position: relative;
        /* z-index: 1; */
        z-index: -1;
        border-radius: 10px; /* FX */
    }

    .portfolio-item:nth-of-type(2n+1) .portfolio-image {
        grid-column: 1 / 8;
    }

    .portfolio-item .portfolio-image .img {
        border-radius: 10px; /* FX */
        mix-blend-mode: multiply;
        /* filter: grayscale(100%) contrast(1) brightness(90%); */
        filter: grayscale(20%) contrast(1) brightness(50%);
    }

    .portfolio-image-wrapper-constrained {
        /* display: inline-block; */
        border-radius: 10px;
    }

    .portfolio-image-wrapper {
        position: relative;
        overflow: hidden;
                border-radius: 10px;
    }

    .portfolio-image-wrapper img {
        bottom: 0;
        height: 100%;
        left: 0;
        margin: 0;
        max-width: none;
        padding: 0;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        object-fit: cover;
        border-radius: 10px;
    }

    /* img[alt=""], img:not([alt]) {
        filter: blur(5px);
    } */

    .portfolio-image-wrapper [data-main-image] {
        opacity: 0;
        transform: translateZ(0);
        transition: opacity .25s linear;
        will-change: opacity;
        border-radius: 10px;
    }

    /* img[Attributes Style] {
        width: 700px;
        aspect-ratio: auto 700 / 438;
        height: 438px;
    } */

    .portfolio-item .portfolio-image a {
        width: 100%;
        height: 100%;
        /* background-color: var(--green); */
        /* background-color: Gainsboro; */
        background-color: #64ffda;
        border-radius: 10px; /* FX */
        vertical-align: middle;
    }

    .portfolio-list a {
        position: relative;
        z-index: 2;
    }

    .portfolio-item .portfolio-image a::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        inset: 0px;
        z-index: 3;
        transition: var(--transition);
        background-color: var(--navy);
        mix-blend-mode: screen;
        border-radius: 10px; /* FX */
    }



    @media (max-width: 768px){
        .portfolio-item .portfolio-image {
            grid-column: 1 / -1;
            height: 100%;
            /* opacity: 0.25; */
            /* opacity: 0.4; */
            opacity: 0.6;
        }
    }

    @media (max-width: 768px){
        .portfolio-item:nth-of-type(2n+1) .portfolio-image {
            grid-column: 1 / -1;
        }
    }

    @media (max-width: 768px){
        .portfolio-item .portfolio-image .img {
            object-fit: cover;
            width: auto;
            height: 100%;
            /* filter: grayscale(100%) contrast(1) brightness(50%); */
            /* filter: grayscale(20%) contrast(1) brightness(50%); */
        }
    }


/* PROJECT IMAGE --------------------------------------------------------------*/










